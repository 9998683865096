import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const paymentsEcosystemAnimation = () => {
    const paymentsEcosystem = document.querySelector('.payments-ecosystem');
    if (paymentsEcosystem && window.innerWidth >= 1024) {
        const imageContainer = paymentsEcosystem.querySelector('.payments-ecosystem__image-container');
        const paymentsImage = paymentsEcosystem.querySelector('.payments-circle');
        const itemContainer = paymentsEcosystem.querySelector('.payments-ecosystem__item-container');
        const iconsContainer = paymentsEcosystem.querySelector('.payments-ecosystem__icons-container');
        const icons = paymentsEcosystem.querySelectorAll('.payments-ecosystem__icon');
        const items = paymentsEcosystem.querySelectorAll('.payments-ecosystem__item');

        const animation = () => {
            imageContainer.classList.add('--active')
            paymentsImage.classList.add('--rotate')
            iconsContainer.classList.add('--rotate')
            itemContainer.classList.add('--active')
            icons.forEach(icon => {
                icon.classList.add('--rotate-inverse')
            })

            imageContainer.addEventListener('transitionend', () => {
                items.forEach((item, index) => {
                    setTimeout(() => {
                        item.classList.add('--active');
                    }, index * 250);
                });
            });
        }
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: paymentsEcosystem,
                start: 'top center',
                onEnter: () => {
                    animation()
                }
            }
        })
    }
}