const ValidationMessages = {
    first_name: {
        empty: 'Name is required',
    },
    last_name: {
        empty: 'Lastname is required',
    },
    email: {
        empty: 'Email is required',
        invalid: 'Invalid email',
    },
    phone_number: {
        empty: 'Phone number is required',
        invalid: 'Invalid phone number',
    },
    default: {
        empty: 'Required'
    }
};

export default ValidationMessages;