export const switcherHandler = () => {
    const switcherSection = document.querySelector('.switcher')

    if(switcherSection){
        const switchBall = switcherSection.querySelector('.switcher__switch-ball')
        const tags = switcherSection.querySelectorAll('.switcher__tag')
        const items = switcherSection.querySelectorAll('.switcher__item')

        tags.forEach(tag => {
            tag.addEventListener('click', function(){
                tags.forEach(tag => {
                    tag.classList.remove('--active')
                })
                tag.classList.add('--active')

                const tagData = tag.getAttribute('data')
                if(tagData === 'right'){
                    switchBall.classList.add('--right')
                    items.forEach(item => {
                        const itemData = item.getAttribute('data')
                        if(itemData == tagData){
                            item.classList.remove('--hide')
                        } else {
                            item.classList.add('--hide')
                        }
                    })
                } else {
                    switchBall.classList.remove('--right')
                    items.forEach(item => {
                        const itemData = item.getAttribute('data')
                        if(itemData == tagData){
                            item.classList.remove('--hide')
                        } else {
                            item.classList.add('--hide')
                        }
                    })
                }
            })
        })
    }
}