import EgoModal from "../components/ego-modal";
import { lightbox } from "./sliders";

export const initModals = () => {

    const modalElements = {
        customer: document.getElementById('modalCustomer'),
        lightbox: document.getElementById('lightbox'),
    };

    if (modalElements.customer) {
        const customerModal = new EgoModal({
            element: modalElements.customer
        });
        //CHECK IF IS THE FIRST TIME THE USER VISIT THE SITE
        let visited = window.localStorage.getItem('VERITRAN_VISITED');
        if (!visited) {
            customerModal.open();
            customerModal.onCancel = () => {
                window.localStorage.setItem('VERITRAN_VISITED', 'FALSE');
            }
            customerModal.onConfirm = () => {
                window.localStorage.setItem('VERITRAN_VISITED', 'TRUE');
                location.href = modalElements.customer.querySelector("#modal-confirm").getAttribute("data-redirect");
            }
        }
        else {
            if (visited === "TRUE") location.href = modalElements.customer.querySelector("#modal-confirm").getAttribute("data-redirect");
        }
    }

    //LIGHTBOX
    if (modalElements.lightbox) {
        let openLightbox = document.querySelectorAll(`#open-lightbox`);
        let lightboxSwiper = document.querySelector('#lightbox-swiper .swiper-wrapper')

        openLightbox.forEach((btn) => {
            btn.addEventListener("click", function (e) {
                // Create an array with the children to show in the slider
                let sliderItems = Array.from(
                    e.currentTarget.parentNode.children
                );

                let index = sliderItems.indexOf(e.currentTarget)
                
                sliderItems.forEach((each) => {
                    let video = each.getAttribute("data-video")
                    let dataToShow = {
                        video: video != 'None' ? video : null,
                        image: each.getAttribute("data-image")
                    }
                    
                    // Crate the div and add 'swiper-slide' class
                    const newDiv = document.createElement("div")
                    newDiv.classList.add('swiper-slide');

                    // If has a video url add a div with iframe
                    // if not, add the <img />
                    if (dataToShow.video) {
                        newDiv.innerHTML = `<div class="lightbox__content__video" id="lightbox-video"><div class="video-responsive"><iframe src="${dataToShow.video}?enablejsapi=1" enablejsapi frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>`;
                    } else {
                        newDiv.innerHTML = `<img src="${dataToShow.image}" />`;
                    }

                    // Add the new component to the parent
                    lightboxSwiper.appendChild(newDiv);
                });
                
                // Open modal
                lightbox(index)
                modalElements.lightbox.classList.add("active");
            });
        });

        // Close modal
        modalElements.lightbox
            .querySelector("#lightbox-close")
            .addEventListener("click", (e) => {
                modalElements.lightbox.classList.remove("active");

                // Delete all swiper items
                lightboxSwiper.innerHTML = ''
            });
    }
    
}

const openModal = (btnId, modal) => {
    document.querySelector(`#${btnId}`).addEventListener('click', e => modal.open())
}
