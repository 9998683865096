export function handleShowMore(){
    const bankingExperience = document.querySelector('.banking-experience');

    if(bankingExperience && window.innerWidth >= 1024){
        const showMoreButton = bankingExperience.querySelector('#show-more');
        const sectionHidden = bankingExperience.querySelector('.banking-experience__solution-item-container.--hide');

        showMoreButton.addEventListener('click', () => {
            sectionHidden.classList.toggle('--hide')
            showMoreButton.classList.toggle('outline')
            if(showMoreButton.classList.contains('outline')){
                showMoreButton.innerHTML = 'HIDE SERVICES'
            } else {
                showMoreButton.innerHTML = 'SHOW ALL'
            }
        })
    } else if(bankingExperience && window.innerWidth < 1024){
        const starterButton = bankingExperience.querySelector('.banking-experience__solution-count.--starter');
        const starterSection = bankingExperience.querySelector('.banking-experience__solution-item-container.--hide.--starter');
        const advancedButton = bankingExperience.querySelector('.banking-experience__solution-count.--advanced');
        const advancedSection = bankingExperience.querySelector('.banking-experience__solution-item-container.--hide.--advanced');
        const ultimateButton = bankingExperience.querySelector('.banking-experience__solution-count.--ultimate');
        const ultimateSection = bankingExperience.querySelector('.banking-experience__solution-item-container.--hide.--ultimate');

        const openCloseSection = (button, section) => {
            button.addEventListener('click', () => {
                section.classList.toggle('--hide')
                let icon = button.querySelector('.open-close-icon')
                icon.classList.toggle('--open')
            })
        }

        openCloseSection(starterButton, starterSection)
        openCloseSection(advancedButton, advancedSection)
        openCloseSection(ultimateButton, ultimateSection)
    }
}

export function handleModalPackage(){
    const bankingExperience = document.querySelector('.banking-experience');
    if(bankingExperience){
        const starter = document.querySelector('#starter-modal')
        const advanced = document.querySelector('#advanced-modal')
        const ultimate = document.querySelector('#ultimate-modal')
        const modalContent = document.querySelectorAll('.package-modal__content')
        const closeButtons = bankingExperience.querySelectorAll('.close-icon');
        const buttons = bankingExperience.querySelectorAll('#modal-open');
        const changeModalButtons = bankingExperience.querySelectorAll('#change-modal');

        const openModal = (modal) => {
            modal.classList.add('--active')
            modal.classList.remove('--closed')            
        }

        const changeModal = (closeModalFirst, closeModalSecond, newModal) => {
            closeModal(closeModalFirst)
            closeModal(closeModalSecond)
            setTimeout(() => {
                openModal(newModal)
            }, 200);
        }

        const closeModal = (modal) => {
            modal.classList.remove('--active')
            setTimeout(() => {
                modal.classList.add('--closed')
            }, 200);
            document.querySelector('body').classList.remove('--no-scroll');
        }

        changeModalButtons.forEach(button => {
            button.addEventListener('click', () => {
                if(button.getAttribute('associated-package') === 'starter'){
                    changeModal(advanced, ultimate, starter)
                } else if(button.getAttribute('associated-package') === 'advanced'){
                    changeModal(ultimate, starter, advanced)
                } else if(button.getAttribute('associated-package') === 'ultimate'){
                    changeModal(starter, advanced, ultimate)
                }
            })
        })


        buttons.forEach(button => {
            button.addEventListener('click', () => {
                if(button.getAttribute('associated-package') === 'starter'){
                    openModal(starter)
                    document.querySelector('body').classList.add('--no-scroll');
                } else if(button.getAttribute('associated-package') === 'advanced'){
                    openModal(advanced)
                    document.querySelector('body').classList.add('--no-scroll');
                } else if(button.getAttribute('associated-package') === 'ultimate'){
                    openModal(ultimate)
                    document.querySelector('body').classList.add('--no-scroll');
                }
            })
        })

        closeButtons.forEach(button => {
            button.addEventListener('click', () => {
                if(button.getAttribute('associated-package') === 'starter'){
                    closeModal(starter)
                } else if(button.getAttribute('associated-package') === 'advanced'){
                    closeModal(advanced)
                } else if(button.getAttribute('associated-package') === 'ultimate'){
                    closeModal(ultimate)
                }
            })
        })

        modalContent.forEach(content => {
            content.addEventListener('click', (e) => {
                e.stopPropagation()
            })
        })

        starter.addEventListener('click', (e) => {
            closeModal(starter)
        })

        advanced.addEventListener('click', (e) => {
            closeModal(advanced)
        })

        ultimate.addEventListener('click', (e) => {
            closeModal(ultimate)
        })

    }
}

export function handleTabsContent(){
    const bankingExperience = document.querySelector('.banking-experience');

    if(bankingExperience){
        const modals = bankingExperience.querySelectorAll('.package-modal')

        modals.forEach(modal => {
            let tabs = modal.querySelectorAll('.package-modal__tab')
            let tabsContent = modal.querySelectorAll('.package-modal__bullet-container')

            tabs.forEach(tab => {
                tab.addEventListener('click', () => {
                    tabs.forEach(tab => {
                        tab.classList.remove('--active')
                    })
                    tabsContent.forEach(content => {
                        content.classList.remove('--active')
                    })
                    tab.classList.add('--active')
                    let associatedTab = tab.getAttribute('associated-tab');
                    let associatedContent = modal.querySelector(`ul[associated-content="${associatedTab}"]`)
                    associatedContent.classList.add('--active')
                })
            })
        })
    }
}