import EgoForm from "../components/ego-form";
import { getCookie } from "./tools";
import axios from 'axios';

// Requests default headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');

const europeCountries = [
    "Albania",
    "Andorra",
    "Armenia",
    "Austria",
    "Azerbaijan",
    "Belarus",
    "Belgium",
    "Bosnia And Herzegovina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Faroe Islands",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Gibraltar",
    "Greece",
    "Holy See (Vatican City State)",
    "Hungary",
    "Iceland",
    "Ireland",
    "Isle Of Man",
    "Italy",
    "Kazakhstan",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russian Federation",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom",
]

export const initForms = () => {
    const formElements = {
        contact: document.getElementById('contactForm'),
        download: document.getElementById('downloadForm'),
        interviews: document.getElementById('interviewsForm'),
    };

    if (formElements.contact) {
        const contactForm = new EgoForm({
            element: formElements.contact,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.download) {
        const downloadForm = new EgoForm({
            element: formElements.download,
            submitType: 'POST',
            showMessages: false
        });
    }

    // Add recaptcha and pardot validation in form_interviews
    if (formElements.interviews) {
        const interviewsForm = formElements.interviews;
        const interviewsBtn = interviewsForm.querySelector('.btn');

        interviewsForm.addEventListener('submit', e => {
            e.preventDefault()
            interviewsBtn.classList.add('--loading');
            interviewsBtn.disabled = true;

            const pardotField = document.getElementById('pardot_extra_field')
            if (pardotField && pardotField.value != 0) {
                interviewsBtn.classList.remove('--loading');
                interviewsBtn.disabled = false;
                return
            }

            // Get reCaptcha Token
            const recaptchaToken = document.getElementById('recaptchaToken').value;
            if (recaptchaToken.length != 0) {
                const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

                axios({
                    method: 'POST',
                    url: '/api/recaptcha/',
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRFToken': csrfToken
                    },
                    data: { token: recaptchaToken }
                })
                    .then((resp) => {
                        // Send the form if the data is correct
                        if (resp.data.success) {
                            interviewsForm.action = formep;
                            interviewsForm.submit();
                        }
                    })
                    .catch((err) => {
                        console.log('Recaptcha error');
                        interviewsBtn.classList.remove('--loading');
                        interviewsBtn.disabled = false;
                    })
            } else {
                interviewsBtn.classList.remove('--loading');
                interviewsBtn.disabled = false;
            }
        })
    }
}

export const searchableRadioButtonsGroup = () => {
    const groups = document.querySelectorAll('.form__field.--radio.--searchable')
    groups.forEach(group => {
        const radios = group.querySelectorAll('.form__field__radio');
        const empty = group.querySelector('.form__field__search .empty');
        const options = radios.length;

        group.querySelector('.form__field__search input')
            .addEventListener('input', e => {
                let count = 0;
                radios.forEach(radio => {
                    if (radio.dataset.value.toLowerCase().indexOf(e.target.value.toLowerCase()) === -1) {
                        radio.classList.add('--hidden');
                        radio.querySelector('.form__control').checked = false;
                        ++count;
                    } else {
                        radio.classList.remove('--hidden');
                    }
                });
                if (count >= options) empty.classList.add('--show');
                else empty.classList.remove('--show');
            });
    });
}

export const resendVerificationEmailHandler = () => {
    const resendButton = document.querySelector('.resend-email__button');

    if (resendButton && resendButton.dataset.email) {
        const email = resendButton.dataset.email;
        const url = resendButton.dataset.url;
        resendButton.addEventListener('click', async e => {
            e.preventDefault();
            axios.post(url, {
                email: email
            })
                .then(function (response) {
                    window.location.href = (response.status === 200) ?
                        window.location.href + '?resend=true' :
                        window.location.href + '?resend_error=true';
                })
                .catch(function (error) {
                    window.location.href + '?resend_error=true';
                });
        })
    }
}

export const handleCountrySelect = () => {
    const countrySelect = document.querySelector('.country');
    const europeanDisclaimerCheckbox = document.querySelector('.european_disclaimer');
    const europeanDisclaimerDefaultValue = document.querySelector('.european_disclaimer-default');
    const countryInput = document.querySelector('input[name="country"]');

    const handleEuropeanDisclaimer = (value) => {
        if (europeCountries.find((country) => country === value)) {
            europeanDisclaimerCheckbox.parentElement.classList.remove('--hidden');
            europeanDisclaimerCheckbox.classList.add('--required')

            europeanDisclaimerCheckbox.querySelector('input').disabled = false;
            europeanDisclaimerCheckbox.querySelector('input').required = true;
            europeanDisclaimerDefaultValue.disabled = true;
        }
        else {
            europeanDisclaimerCheckbox.parentElement.classList.add('--hidden');
            europeanDisclaimerCheckbox.classList.remove('--required')

            europeanDisclaimerCheckbox.querySelector('input').disabled = true;
            europeanDisclaimerCheckbox.querySelector('input').required = false;
            europeanDisclaimerDefaultValue.disabled = false;
        }
    }

    // Handle country select change
    if (countrySelect) {
        countrySelect.onchange = (e) => {
            let selectValue = e.target.value;
            handleEuropeanDisclaimer(selectValue);
        }
    }

    // Handle country input change (pais por geoip)
    if(countryInput){
        let selectValue = countryInput.value;
        handleEuropeanDisclaimer(selectValue);
    }
}

export const handleDynamicLabels = () => {
    const form = document.querySelector(".--contact-form-dynamic-fields");

    if (form) {
        const industryField = document.getElementById("industry");
        const dynamicFields = form.querySelectorAll(".--dynamic-field");
        const industryValuesToShowFields = [
            "Banking and payments",
            "Finance",
            "Fintech",
        ];

        const handleIndustryChange = (e) => {
            const value = e.target.value;
            const shouldShowFields = industryValuesToShowFields.includes(value);

            dynamicFields.forEach((field) => {
                if (shouldShowFields) {
                    field.classList.remove("--hidden");
                    field.classList.add("--required");
                    field.querySelector("select").required = true;
                } else {
                    field.classList.add("--hidden");
                    field.classList.remove("--required");
                    field.querySelector("select").required = false;
                }
            });
        };

        industryField.addEventListener("change", handleIndustryChange);
    }
};  

export const bannerCtaForm = () => {
    const bannerCtaForm = document.querySelectorAll('.cta-forms');
    const sideForm = document.querySelector('.side-form');

    if(bannerCtaForm.length > 0 && sideForm){
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const formContainer = sideForm.querySelector('.side-form__form-container');
        const closeSideFormButton = sideForm.querySelector('.close-icon');
        const form = sideForm.querySelector('form');
        const emailField = form.querySelector('#email');
        const successModal = document.querySelector('.success-side-form');
        const successModalCloseButton = successModal.querySelector('.close-button');
        const successModalCloseIcon = successModal.querySelector('.close-icon');

        // Open side form function
        const openSideForm = (bannerEmailField) => {
            sideForm.classList.remove('--hidden');
            sideForm.classList.remove('--closed')
            sideForm.classList.add('--open');
            emailField.value = bannerEmailField.value;
            emailField.closest('div').classList.add('--filled');
            document.querySelector('body').classList.add('--no-scroll');
            setCompanyValue();
        }

        // Close side form function
        const closeSideForm = () => {
            sideForm.classList.remove('--open');
            sideForm.classList.add('--closed');
            setTimeout(() => {
                sideForm.classList.add('--hidden');
            }, 500);
            document.querySelector('body').classList.remove('--no-scroll');
        }

        // Open side form on enter
        bannerCtaForm.forEach(banner => {
            let bannerButton = banner.querySelector('.cta-forms__form-icon');
            let bannerEmailField = banner.querySelector('#email');
            banner.addEventListener('keydown', e => {
                if(e.keyCode === 13){
                    e.preventDefault();
                    if(bannerButton.classList.contains('--active')){
                        openSideForm(bannerEmailField);
                    }
                }
            })
        })

        // Open side form
        bannerCtaForm.forEach(banner => {
            let bannerButton = banner.querySelector('.cta-forms__form-icon');
            let bannerEmailField = banner.querySelector('#email');
            bannerButton.addEventListener('click', e => {
                e.preventDefault();
                if(bannerButton.classList.contains('--active')){
                    openSideForm(bannerEmailField);
                }
            })
        })

        // Add filled class to email field
        bannerCtaForm.forEach(banner => {
            let bannerEmailField = banner.querySelector('#email');
            let bannerButton = banner.querySelector('.cta-forms__form-icon');
            bannerEmailField.addEventListener('input', e => {
                if(e.target.value.length > 0){
                    bannerEmailField.classList.add('--filled');
                } else {
                    bannerEmailField.classList.remove('--filled');
                }
                if(emailRegex.test(e.target.value)){
                    bannerButton.classList.add('--active');
                } else {
                    bannerButton.classList.remove('--active');
                }
            })
        })

        // Close side form
        closeSideFormButton.addEventListener('click', e => {
            e.preventDefault();
            closeSideForm();
        })

        sideForm.addEventListener('click', e => {
            e.preventDefault();
            closeSideForm();
        })

        successModalCloseButton.addEventListener('click', e => {
            e.preventDefault();
            closeSideForm();
        })

        successModalCloseIcon.addEventListener('click', e => {
            e.preventDefault();
            closeSideForm();
        })

        formContainer.addEventListener('click', e => {
            e.stopPropagation();
        })

    }
}

export const disabledSubmitButton = () => {
    const form = document.querySelector('#contactForm');
    
    if (form) {
        const submitButton = form.querySelector('button[type=submit]');
        let requiredInputs = form.querySelectorAll('input[type="text"][required]'); // Campos de entrada de texto requeridos
        let requiredSelects = form.querySelectorAll('select[required]'); // Campos de selección requeridos
        let requiredCheckboxes = form.querySelectorAll('input[type="checkbox"][required]'); // Checkboxes requeridos
        const countrySelect = form.querySelector('#country'); // Campo de selección del país

        // Función para verificar si todos los campos de texto requeridos están llenos
        const checkAllTextFieldsFilled = () => {
            for (const field of requiredInputs) {
                if (!field.value.trim()) {
                    return false;
                }
            }
            return true;
        };

        // Función para verificar si todos los checkboxes requeridos están marcados
        const checkAllCheckboxesChecked = () => {
            for (const checkbox of requiredCheckboxes) {
                if (!checkbox.checked) {
                    return false;
                }
            }
            return true;
        };

        // Función para verificar si todos los campos de selección requeridos tienen un valor seleccionado
        const checkAllSelectsSelected = () => {
            for (const select of requiredSelects) {
                if (select.value === "") {
                    return false;
                }
            }
            return true;
        };

        // Función para habilitar o deshabilitar el botón de envío según el estado de los campos
        const toggleSubmitButton = () => {
            if (checkAllTextFieldsFilled() && checkAllCheckboxesChecked() && checkAllSelectsSelected()) {
                submitButton.classList.remove('--disabled');
                submitButton.disabled = false;
            } else {
                submitButton.classList.add('--disabled');
                submitButton.disabled = true;
            }
        };

        // Agregar un EventListener 'input' a los campos de texto requeridos
        requiredInputs.forEach((field) => {
            field.addEventListener('input', toggleSubmitButton);
        });

        // Agregar un EventListener 'change' a los checkboxes requeridos
        requiredCheckboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', toggleSubmitButton);
        });

        // Agregar un EventListener 'change' al campo de selección del país
        countrySelect.addEventListener('change', () => {
            // Actualizar la lista de campos requeridos según el país seleccionado
            requiredInputs = form.querySelectorAll('input[type="text"][required]');
            requiredCheckboxes = form.querySelectorAll('input[type="checkbox"][required]');
            requiredCheckboxes.forEach((checkbox) => {
                checkbox.addEventListener('change', toggleSubmitButton);
            });
            // Llamar a toggleSubmitButton para actualizar el estado del botón
            toggleSubmitButton();
        });

        // Agregar un EventListener 'change' a los campos de selección requeridos
        requiredSelects.forEach((select) => {
            select.addEventListener('change', toggleSubmitButton);
        });

        // Llamar a toggleSubmitButton inicialmente para establecer el estado inicial del botón de envío
        toggleSubmitButton();
    }
};

export const handleCountryLabelSideForm = () => {
    const sideForm = document.querySelector('.side-form');
    const contactForm = document.querySelector('.contact-form');
    if (sideForm) {
        const countrySelect = sideForm.querySelector('.country.form__control.form__field__select');
        if(countrySelect){
            const jobTitleField = sideForm.querySelector('#job_title');
            const jobTitleContainer = jobTitleField.parentElement;
            jobTitleContainer.classList.remove('--full-width');
        }
    } else if (contactForm) {
        const countrySelect = contactForm.querySelector('.country.form__control.form__field__select');
        if(countrySelect){
            const jobTitleField = contactForm.querySelector('#job_title');
            const jobTitleContainer = jobTitleField.parentElement;
            jobTitleContainer.classList.remove('--full-width');
        }
    }
}

export const handleCompanyHiddenLabel = () => {
    const form = document.querySelector('#contactForm');
    if(form){
        const emailField = form.querySelector('#email')
        emailField.addEventListener('input', e => {
            setCompanyValue();
        })
    }
}

export function handldePackageInput(){
    const bankingExperience = document.querySelector('.banking-experience');
    const sideForm = document.querySelector('.side-form');

    if(bankingExperience && sideForm){
        const buttons = bankingExperience.querySelectorAll('#make-appointment')
        const form = sideForm.querySelector('form')
        const inputPackage = form.querySelector('#associated_package')
        const starter = document.querySelector('#starter-modal')
        const advanced = document.querySelector('#advanced-modal')
        const ultimate = document.querySelector('#ultimate-modal')

        const closeModalOpenForm = (modal) => {
            modal.classList.remove('--active')
            setTimeout(() => {
                modal.classList.add('--closed')
                sideForm.classList.remove('--hidden');
                sideForm.classList.remove('--closed')
                sideForm.classList.add('--open');
                document.querySelector('body').classList.add('--no-scroll');
            }, 200);
            document.querySelector('body').classList.remove('--no-scroll');
        }

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                let associatedPackage = button.getAttribute('associated-package')
                inputPackage.value = associatedPackage
                if(associatedPackage === 'starter'){
                    closeModalOpenForm(starter)
                } else if(associatedPackage === 'advanced'){
                    closeModalOpenForm(advanced)
                } else if(associatedPackage === 'ultimate'){
                    closeModalOpenForm(ultimate)
                }
            })
        })
    }
}

//AUXILIAR FUNCTIONS

// Set company value function
const setCompanyValue = () => {
    const form = document.querySelector('#contactForm');
    const company = form.querySelector('#company');
    const email = form.querySelector('#email');

    const emailDomain = getEmailDomain(email.value.toLowerCase());

    // Lista de dominios a excluir
    const excludedDomains = ["yahoo", "gmail", "hotmail", "outlook"];

    if (excludedDomains.some(domain => emailDomain.includes(domain))) {
        company.value = '';
    } else {
        var atIndex = email.value.indexOf("@");
        var dotIndex = email.value.indexOf(".", atIndex);
        if (atIndex !== -1 && dotIndex !== -1){
            company.value = email.value.substring(atIndex + 1, dotIndex);
        } else {
            company.value = '';
        }
    }
}

// Función para obtener el dominio del correo electrónico
const getEmailDomain = (email) => {
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
        return email.substring(atIndex + 1);
    }
    return '';
}

