export function handleAccordion(){
    const pricingChart = document.querySelector('.pricing-chart');
    if(pricingChart){
        const accordion = pricingChart.querySelectorAll('.--accordion');
        accordion.forEach((item) => {
            let accordionIcon = item.querySelector('.pricing-chart__detail-list-dropdown-img');
            let accordionDropdown = item.querySelector('.pricing-chart__detail-list-dropdown');
            item.addEventListener('click', () => {
                if(item.classList.contains('--hide')){
                    item.classList.remove('--hide');
                    accordionIcon.classList.add('--hide');
                } else {
                    item.classList.add('--hide');
                    accordionIcon.classList.remove('--hide');
                }
            })
        })
    }
}

export function handleInternalDropdown(){
    const pricingChart = document.querySelector('.pricing-chart');
    if(pricingChart){
        const items = pricingChart.querySelectorAll('.--has-dropdown');
        items.forEach((item) => {
            let dropdownContent = item.querySelector('.internal-dropdown');
            let dropdownIcon = item.querySelector('.--internal-dropdown-icon');
            let iconLine = item.querySelectorAll('.icon-line')
            item.addEventListener('click', () => {
                if(dropdownContent.classList.contains('--active')){
                    dropdownContent.classList.remove('--active');
                    dropdownIcon.classList.add('--hide');
                    iconLine?.forEach((icon) => {
                        icon.classList.remove('--active');
                    })
                } else {
                    dropdownContent.classList.add('--active');
                    dropdownIcon.classList.remove('--hide');
                    iconLine?.forEach((icon) => {
                        icon.classList.add('--active');
                    })
                }
            });
        });
    }
}
