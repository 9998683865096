export const handleDynamicUtm = () => {
    const formUtm = document.querySelector('[name="utm_campaign"]')
    const eventCards = document.querySelectorAll(".card-event")

    if(formUtm && eventCards){
        eventCards.forEach((card) => {
            let appointmentButton = card.querySelector(".appointment-button")
            if(appointmentButton){
                let eventUtm = appointmentButton.getAttribute("data-utm")
                appointmentButton.addEventListener("click", () => {
                    formUtm.value = eventUtm
                })
            }
        })
    }
};
