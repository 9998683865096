import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { BREAKPOINTS } from './tools';

export const initSliders = () => {
    const sliderElements = {
        hero: document.getElementById('hero-carousel-swiper'),
        testimonials: document.getElementById('items-testimonials-swiper'),
        cardsLink: document.getElementById('items-cards-link-swiper'),
        brandsFirstRow: document.getElementById('items-brands-first-row-swiper'),
        brandsSecondRow: document.getElementById('items-brands-second-row-swiper'),
        news: document.getElementById('items-news-swiper'),
        bannerExpandingCards: document.getElementById('banner-expanding-cards-swiper'),
        articleCarouselCards: document.getElementById('article-carousel-cards-swiper'),
        articleEvent: document.getElementById('article-event-swiper'),
        lightbox: document.getElementById('lightbox-swiper'),
        upcomingEvents: document.getElementById('upcoming-events-swiper'),
        pastEvents: document.getElementById('past-events-swiper'),
        heroSlider: document.getElementById('hero-slider-swiper'),
        tabComponent: document.querySelector('.tabs-component'),
        slideCards: document.querySelector('.slide-cards'),
        bannerSlider: document.querySelector('.banner-slider'),
    };

    if (sliderElements.hero) heroSlider();
    if (sliderElements.testimonials) itemsTestimonials();
    if (sliderElements.cardsLink) itemsCardsLink();
    if (sliderElements.brandsFirstRow) itemsBrandsFirstRow();
    if (sliderElements.brandsSecondRow) itemsBrandsSecondRow();
    if (sliderElements.news) itemsNews();
    if (sliderElements.bannerExpandingCards) bannerExpandingCards();
    if (sliderElements.articleCarouselCards) articleCarouselCards();
    if (sliderElements.articleEvent) articleEvent();
    // if (sliderElements.lightbox) lightbox();
    if (sliderElements.upcomingEvents) upcomingEvents();
    if (sliderElements.pastEvents) pastEvents();
    if (sliderElements.heroSlider) heroSliderBanner();
    if (sliderElements.tabComponent) tabsComponentSlider();
    if (sliderElements.slideCards) slideCards();
    if (sliderElements.bannerSlider) bannerSlider();
}

const heroSlider = () => {
    var swiper = new Swiper("#hero-carousel-swiper", {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 'auto',
        spaceBetween: 15,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        breakpoints: {
            [BREAKPOINTS.MD]: {
                spaceBetween: -185,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,        
                },
            },
        }
    });
}

const itemsTestimonials = () => {
    if (window.innerWidth <= BREAKPOINTS.MD) {
        var swiper = new Swiper("#items-testimonials-swiper", {
            slidesPerView: 'auto',
            spaceBetween: 15,
            centeredSlides: false,
        });
    }
}

const itemsCardsLink = () => {
    if (window.innerWidth <= BREAKPOINTS.LG) {
        var swiper = new Swiper("#items-cards-link-swiper", {
            slidesPerView: 'auto',
            spaceBetween: 24,
            centeredSlides: false,
        });
    }
}

const itemsBrandsFirstRow = () => {
    setTimeout(() => {
        var swiper = new Swiper("#items-brands-first-row-swiper", {
            modules: [Autoplay],
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 0,
            freeMode: true,
            loop: true,
            speed: 3000,
            autoplay: {
                delay: 1,
                reverseDirection: false,
                disableOnInteraction: false,
            },
        });
    }, 1000)
}

const itemsBrandsSecondRow = () => {
    setTimeout(() => {
        var swiper = new Swiper("#items-brands-second-row-swiper", {
            modules: [Autoplay],
            slidesPerView: 'auto',
            centeredSlides: true,
            freeMode: true,
            loop: true,
            speed: 3000,
            autoplay: {
                delay: 1,
                reverseDirection: true,
                disableOnInteraction: false,
            },
        });
    }, 1000);
}

const itemsNews = () => {
    if (window.innerWidth <= BREAKPOINTS.XL) {
        var swiper = new Swiper("#items-news-swiper", {
            slidesPerView: 'auto',
            spaceBetween: 56,
            centeredSlides: false,
            breakpoints: {
                [BREAKPOINTS.MD]: {
                    slidesPerView: 3,
                },
            }
        });
    }
}

const bannerExpandingCards = () => {
    if (window.innerWidth <= BREAKPOINTS.MD) {
        var swiper = new Swiper("#banner-expanding-cards-swiper", {
            slidesPerView: 'auto',
            spaceBetween: 16,
            centeredSlides: false,
        });
    }
}

const articleCarouselCards = () => {
    if (window.innerWidth <= BREAKPOINTS.XL) {
        var swiper = new Swiper("#article-carousel-cards-swiper", {
            slidesPerView: 'auto',
            spaceBetween: 16,
            centeredSlides: false,
        });
    }
}

const articleEvent = () => {
    var swiper = new Swiper("#article-event-swiper", {
        modules: [Pagination],
        slidesPerView: 1,
        centeredSlides: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,        
        },
    });
}

export const lightbox = (initialSlider) => {
    window.lightboxSwiper = new Swiper("#lightbox-swiper", {
        modules: [Navigation],
        slidesPerView: 1,
        centeredSlides: true,
        initialSlide: initialSlider,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        on: {
            slideChangeTransitionStart: () => {
                let slides = lightboxSwiper.slides;

                slides.forEach((slide) => {
                    let youtubeIframe = slide.querySelector(
                        'iframe[src*="youtube.com"]'
                    );

                    if (youtubeIframe) {
                        youtubeIframe.contentWindow?.postMessage(
                            '{"event":"command","func":"pauseVideo","args":""}',
                            "*"
                        );
                    }
                });
            },
        },
    });
};

const upcomingEvents = () => {
    window.lightboxSwiper = new Swiper("#upcoming-events-swiper", {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
            nextEl: ".swiper-button-next.upcoming-events",
            prevEl: ".swiper-button-prev.upcoming-events",
        },
        breakpoints: {
          // when window width is >= 718px
          718: {
            slidesPerView: 2,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3,
          },
        }
    });
};

const pastEvents = () => {
    window.lightboxSwiper = new Swiper("#past-events-swiper", {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
          nextEl: ".swiper-button-next.past-events",
          prevEl: ".swiper-button-prev.past-events",
        },
        breakpoints: {
          // when window width is >= 718px
          718: {
            slidesPerView: 2,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3,
          },
        }
    });
};

const heroSliderBanner = () => {
    const swiper = new Swiper("#hero-slider-swiper", {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 8000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: ".swiper-pagination",
        },
        on: {
            slideChange: function () {
                updateHeaderStyle();
            },
        },
    });

    let scrolledToTop = true;

    function updateHeaderStyle() {
        setTimeout(() => {
            const activeSlide = document.querySelector('.swiper-slide-active');
            const header = document.querySelector('.header');
            const button = document.querySelector('#btn-talk');

            if (!scrolledToTop) {
                // Limpiar clases anteriores
                header.classList.remove('--transparent-white', '--transparent-blue');
                button.classList.remove('outline-white');
            } else if (activeSlide) {
                const headerType = activeSlide.getAttribute('header-type');

                // Limpiar clases anteriores
                header.classList.remove('--transparent-white', '--transparent-blue');
                button.classList.remove('outline-white');

                // Aplicar clase según el tipo de encabezado
                if (headerType === 'white') {
                    header.classList.add('--transparent-white');
                    button.classList.add('outline-white');
                } else if (headerType === 'blue') {
                    header.classList.add('--transparent-blue');
                    button.classList.remove('outline');
                }
            }
        }, 100);
    }

    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            scrolledToTop = false;
        } else {
            scrolledToTop = true;
        }
        updateHeaderStyle();
    });

    updateHeaderStyle();
};

const tabsComponentSlider = () => {
    const tabComponent = document.querySelectorAll('.tabs-component');

    tabComponent.forEach((tabComponent) => {
        const tabSlider = tabComponent.querySelector('.swiper');
        const nextButton = tabComponent.querySelector('.swiper-button-next');
        const prevButton = tabComponent.querySelector('.swiper-button-prev');
        const wrapper = tabComponent.querySelector('.swiper-wrapper');

        const swiper = new Swiper(tabSlider, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 8,
            centeredSlides: true,
            slideToClickedSlide: true,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            },
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 'auto',
                },
            }
        });

        // Ajusta el margen izquierdo del contenedor para que el slide activo quede alineado a la izquierda
        const property = window.getComputedStyle(wrapper, null).getPropertyValue('transform');
        const matriz = property.match(/matrix\(([^)]+)\)/);
        const valueTransform = matriz[1].split(',');
        const value = parseFloat(valueTransform[4]);
        wrapper.style.marginLeft = `-${value}px`;

        const tabItems = tabComponent.querySelectorAll('.swiper-slide');
        const tabContents = tabComponent.querySelectorAll('[data-tab-index]');

        // Función para actualizar la pestaña activa
        const updateActiveTab = (index) => {
            tabItems.forEach((tab, i) => {
                if (i === index) {
                    tab.classList.add('active');
                } else {
                    tab.classList.remove('active');
                }
            });

            tabContents.forEach((content) => {
                const dataIndex = content.getAttribute('data-tab-index');
                if (dataIndex === index.toString()) {
                    content.classList.remove('--hidden');
                } else {
                    content.classList.add('--hidden');
                }
            });
        };

        // Inicialmente, muestra la primera pestaña activa
        updateActiveTab(1);

        // Maneja el evento de cambio de pestaña del Swiper
        swiper.on('slideChange', () => {
            updateActiveTab(swiper.activeIndex + 1);
        });

    });
};

const slideCards = () => {
    const slideCards = document.querySelector('.slide-cards');
    const swiperContainer = slideCards.querySelector('.swiper')
    const swiper = new Swiper(swiperContainer, {
        modules: [Pagination],
        slidesPerView: 'auto',
        loop: true,
        centeredSlides: true,
        slideToClickedSlide: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,        
        },
    });

}

const bannerSlider = () => {
    const bannerSlider = document.querySelector('.banner-slider');
    const swiperContainer = bannerSlider.querySelector('.swiper')
    const icons = bannerSlider.querySelectorAll('.banner-slider__icon');

    const swiper = new Swiper(swiperContainer, {
        modules: [Pagination, Autoplay],
        slidesPerView: 1,
        rewind: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,        
        },
    });

    icons.forEach((icon) => {
        icon.addEventListener('click', () => {
            const index = icon.getAttribute('associated-slide');
            console.log(index)
            swiper.slideTo(index);
        });
    });
}