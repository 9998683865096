export const filterResults = () => {
    const input = document.querySelector(".search-input input");
    const liItems = document.querySelectorAll(".search-box__item-list li");
    const titleResults = document.getElementById("titleResults");
    const titleNoResults = document.getElementById("titleNoResults");
    const itemNoResults = document.getElementById("itemNoResults");
    const solutionsTexts = {
        spa: "soluciones innovadoras",
        eng: "innovative solutions",
        por: "soluções inovadoras",
    };

    const isSolutionText = (str) => {
        return (
            str.includes(solutionsTexts.spa) ||
            str.includes(solutionsTexts.eng) ||
            str.includes(solutionsTexts.por)
        );
    };

    if (input) {
        const emptyOption = [...liItems].find((li) => {
            let text = li.getElementsByTagName("a")[0].innerText.toLowerCase();
            return isSolutionText(text);
        });
        itemNoResults.append(emptyOption);

        input.addEventListener("keyup", (e) => {
            let text = e.target.value
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .trim();

            filterItems(text);
            toggleElements();
        });

        const filterItems = (text) => {
            liItems.forEach((item) => {
                let textContent = item
                    .getElementsByTagName("a")[0]
                    .innerHTML.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                if (
                    !textContent.includes(text) ||
                    isSolutionText(textContent)
                ) {
                    item.style.display = "none";
                } else {
                    item.style.display = "";
                }
            });
        };

        const toggleElements = () => {
            let hiddenItems = document.querySelectorAll(
                '.search-box__item-list li[style*="display: none"]'
            );

            if (hiddenItems.length == liItems.length - 1) {
                titleResults.style.display = "none";
                titleNoResults.style.display = "";
                titleNoResults.style.marginBottom = "0px";
                itemNoResults.style.display = "";
                document.querySelector('#itemNoResults li').style.display = ""
            } else {
                titleResults.style.display = "";
                titleNoResults.style.display = "none";
                itemNoResults.style.display = "none";
            }
        };
    }
};
