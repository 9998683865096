export const footerDropdown = () => {
    const footer = document.querySelector('.footer');
    const dropdownContainer = footer.querySelector('.dropdown__container');
    if(footer && dropdownContainer){
        const categories = dropdownContainer.querySelectorAll('.dropdown__category');
        categories.forEach(category => {
            const dropdownList = category.querySelector('.dropdown__list');
            const dropdownTitle = category.querySelector('.dropdown__title');
            const dropdownIcon = category.querySelector('.dropdown__icon');
            dropdownTitle.addEventListener('click', () => {
                dropdownList.classList.toggle('--active');
                dropdownIcon.classList.toggle('--active');
            })
        }
        )
    }
}