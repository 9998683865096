import { docReady } from "./modules/tools";
import { initForms, handleCountrySelect, bannerCtaForm, disabledSubmitButton, handleCountryLabelSideForm, handleCompanyHiddenLabel, handldePackageInput } from "./modules/forms";
import { initSliders } from "./modules/sliders";
import { initPodcasts } from "./modules/podcasts";
import { mobileMenuFunctionality, notificationBarFunctionality, headerSliderHeroFunctionality, headerTransparentFunctionality } from "./modules/header";
import { initModals } from "./modules/modals";
import { initCardsActions } from "./modules/cards";
import { initAnimations } from "./modules/animations";
import { filterResults } from "./modules/bannerSearchInput";
import { handleCookies } from "./components/cookie-bar";
import { handleFAQs } from "./components/faqs";
import { socialSharing } from "./modules/social-sharing";
import { handleAnchorButton, useCasesDropdown, useCasesDynamicId } from "./components/use-cases";
// import { caption } from "./modules/blog";
import { handleDynamicUtm } from "./modules/events";
import { handleAccordion, handleInternalDropdown } from "./components/pricing-chart";
import { paymentsEcosystemAnimation } from "./components/payments-ecosystem";
import { footerDropdown } from "./components/footer";
import { handleModalPackage, handleShowMore, handleTabsContent } from "./components/banking-experience";
import { switcherHandler } from "./components/switcher";

docReady(() => {
    //COMPONENTS
    mobileMenuFunctionality();
    notificationBarFunctionality();
    headerSliderHeroFunctionality();
    initSliders();
    useCasesDropdown();
    useCasesDynamicId();
    handleAccordion();
    handleInternalDropdown();
    footerDropdown();
    handleAnchorButton();
    paymentsEcosystemAnimation();
    headerTransparentFunctionality();
    handleShowMore();
    handleModalPackage()
    handleTabsContent();
    switcherHandler();
    //FORMS
    initForms();
    handleCountrySelect();
    bannerCtaForm();
    disabledSubmitButton();
    handleCountryLabelSideForm();
    handleCompanyHiddenLabel();
    handldePackageInput();
    //MODALS
    initModals();
    //CARDS
    initCardsActions();
    //PODCASTS
    initPodcasts();
    //ANIMATIONS
    initAnimations();
    //BANNER SEARCH - FILTER RESULTS
    filterResults();
    //COOKIE BAR
    handleCookies();
    //COOKIE BAR
    handleFAQs();
    //COOKIE BAR
    socialSharing();
    //BLOG
    // caption()
    //EVENTS
    handleDynamicUtm();
});