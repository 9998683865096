import { BREAKPOINTS } from './tools';

export const initCardsActions = () => {
    const cards = document.querySelectorAll('.card-flip')
    if (cards) {
        cards.forEach(card => {
            let btnFlip = card.querySelector('#btn-flip-card');
            if (btnFlip) {
                btnFlip.addEventListener('click', e => {
                    card.classList.add('active');
                })
                card.querySelector('#btn-unflip-card').addEventListener('click', e => {
                    card.classList.remove('active');
                })
            }
        })
    }

    //CARDS SERVICE
    if (window.innerWidth <= BREAKPOINTS.LG) {
        const cardsService = document.querySelectorAll('.card-service')
        if (cardsService) {
            cardsService.forEach(card => {
                let btnOpenContent = card.querySelector('#btn-open-content');
                if (btnOpenContent) {
                    btnOpenContent.addEventListener('click', e => {
                        card.classList.toggle('active');
                    })
                }
            })
        }
    }

    //GO BACK BTN
    const goBackBtn = document.getElementById('go-back-btn')
    if (goBackBtn) {
        goBackBtn.addEventListener('click', e => {
            history.back();
        })
    }

    //EXPANDED PANELS
    if (window.innerWidth > BREAKPOINTS.MD) {
        const panels = document.querySelectorAll('.expanded-panel')
        if (panels) {
            const removeActiveClasses = () => {
                panels.forEach(panel => {
                    panel.classList.remove('active');
                })
            }

            panels.forEach(panel => {
                panel.addEventListener('mouseover', e => {
                    removeActiveClasses();
                    panel.classList.add('active');
                })
                panel.addEventListener('mouseout', e => {
                    removeActiveClasses();
                    panels[0].classList.add('active');
                })
            })
        }
    }

    //BANNER SEARCH
    const bannerSearchInput = document.getElementById('banner-search-input');
    const bannerSearchResults = document.getElementById('banner-search-results');
    
    if (bannerSearchInput && bannerSearchResults) {
        document.addEventListener('click', e => {
            const isClickInside = bannerSearchResults.contains(e.target) || bannerSearchInput.contains(e.target)
          
            if (!isClickInside) {
                bannerSearchResults.classList.add('hidden');
            }
        })
        bannerSearchInput.addEventListener('keyup', e => {
            if (e.target.value.length >= 3) {
                //TODO: Implementar request
                bannerSearchResults.classList.remove('hidden');
            }
            else {
                bannerSearchResults.classList.add('hidden');
            }
        })
        bannerSearchInput.addEventListener('click', e => {
            if (e.target.value.length >= 3) {
                bannerSearchResults.classList.remove('hidden');
            }
        })
    }

    //CATEGORIES
    const categoriesBtn = document.getElementById('categories-dropdown');
    const categoriesMenu = document.getElementById('categories-menu');
    
    if (categoriesBtn && categoriesMenu) {
        document.addEventListener('click', e => {
            const isClickInside = categoriesMenu.contains(e.target) || categoriesBtn.contains(e.target);
          
            if (!isClickInside) {
                categoriesMenu.parentElement.classList.remove('active');
            }
        })
        categoriesBtn.addEventListener('click', e => {
            categoriesMenu.parentElement.classList.add('active');
        })
    }

    //ICONS ACCORDION
    const iconsAccordion = document.getElementById('icons-accordion');

    if (iconsAccordion) {
        const sectionID = '#icons-accordion';
    
        const tabLinks = iconsAccordion.querySelectorAll(`${sectionID} #tabLink`)
        const tabContents = iconsAccordion.querySelectorAll(`${sectionID} .tabContent`)
    
        const removeActiveClasses = () => {
            tabLinks.forEach(tabLink => {
                tabLink.parentElement.classList.remove('active');
            })
            tabContents.forEach(tabContent => {
                tabContent.classList.remove('active');
            })
        }
    
        tabLinks[0].parentElement.classList.add('active');
        tabContents[0].classList.add('active');
    
        tabLinks.forEach(tabLink => {
            tabLink.addEventListener('click', e => {
                if (window.innerWidth > BREAKPOINTS.MD) {
                    if (window.innerWidth > BREAKPOINTS.MD) removeActiveClasses();
                    let tabItem = tabLink.getAttribute("data-tab");
                    let selectedTabContent = document.querySelector(`${sectionID} #tabContent-${tabItem}`);
                    selectedTabContent.classList.add('active');
                }
                tabLink.parentElement.classList.toggle('active');
            })
        })
    }

    //USE CASES TABS
    const useCasesSections = document.querySelectorAll('.items-use-cases');

    if (useCasesSections) {
        useCasesSections.forEach(eachUseCases => {
            const sectionID = `#${eachUseCases.id}`;
    
            const useCasesTabBtns = eachUseCases.querySelectorAll(`${sectionID} #tabBtn`)
            const useCasesTabContents = eachUseCases.querySelectorAll(`${sectionID} .items-use-cases__item`)
    
            const removeActiveClasses = () => {
                useCasesTabBtns.forEach(tabLink => {
                    tabLink.parentElement.classList.remove('active');
                })
                useCasesTabContents.forEach(tabContent => {
                    tabContent.classList.remove('active');
                })
            }
    
            useCasesTabBtns.forEach(tabLink => {
                tabLink.addEventListener('click', e => {
                    if (window.innerWidth > BREAKPOINTS.MD) {
                        if (window.innerWidth > BREAKPOINTS.MD) removeActiveClasses();
                        let tabItem = tabLink.getAttribute("data-tab");
                        let selectedTabContent = document.querySelector(`${sectionID} #tabContent-${tabItem}`);
                        selectedTabContent.classList.add('active');
                    }
                    useCasesTabBtns.forEach(btn => {
                        btn.classList.remove('active'); 
                        btn.parentElement.classList.remove('active')
                    })
                    tabLink.classList.toggle('active');
                    tabLink.parentElement.classList.toggle('active');
                })
            })
        })
    }
}