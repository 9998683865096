export const handleCookies = () => {
    const formId = "cookieBarForm";
    const cookieBarForm = document.getElementById(formId);
    const rejectBtn = document.querySelector(`#${formId} #rejectBtn`);

    rejectBtn.addEventListener("click", () => {
        const checkboxes = document.querySelectorAll(`#${formId} li input[type="checkbox"]`);
        checkboxes.forEach((checkbox) => {
            if (!checkbox.disabled) {
                checkbox.checked = false;
            }
        });
        cookieBarForm.submit()
    });
};
