export const handleFAQs = () => {
    const faqsContainer = document.getElementById("faqs");
    const faqsHeader = document.querySelector(".faqs__header");
    if (faqsContainer && faqsHeader) {
        const toggleContent = () => {
            faqsContainer.classList.toggle("open");
        };

        faqsHeader.addEventListener("click", () => toggleContent());
    }
};
