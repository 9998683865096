export default class EgoModal {
    constructor({
        element,
        onConfirm, 
        onCancel,
        debug
    }) {
        this.modal = element;
        this.onConfirm = onConfirm || null;
        this.onCancel = onCancel || null;
        this.debug = debug || false;

        this.declareHandlers();
        if (this.debug) this.showLog('initialized!');
    }

    confirm() {
        if (typeof this.onConfirm == 'function') {
            this.onConfirm();
        }
    }

    cancel() {
        if (typeof this.onConfirm == 'function') this.onCancel();
    }

    close() {
        this.modal.classList.remove('active');
    }

    open() {
        this.modal.classList.add('active');
    }

    declareHandlers() {
        const closeButton = this.modal.querySelector('#modal-close');
        closeButton.addEventListener('click', e => {
            this.close();
        })

        const confirmButton = this.modal.querySelector('#modal-confirm');
        confirmButton.addEventListener('click', e => {
            this.confirm();
            this.close();
        })

        const cancelButton = this.modal.querySelector('#modal-cancel');
        cancelButton.addEventListener('click', e => {
            this.cancel();
            this.close();
        })
    }

    showLog(msg) {
        console.log('::EgoModal:: ' + msg);
    }
}