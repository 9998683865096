export const initPodcasts = () => {
    const podcasts = document.getElementById('podcasts');
    if (podcasts) {
        const tabLinks = podcasts.querySelectorAll('#tablink');
        const tabContents = podcasts.querySelectorAll('#tabcontent');

        tabLinks.forEach(tab => {
            tab.addEventListener('click', function(e) {
                let name = this.getAttribute("data-name");
                let selectedTab = podcasts.querySelector(`.${name}`);

                tabLinks.forEach(tabLink => {
                    tabLink.classList.remove('active');
                })
                this.classList.add('active');

                //Si no tiene la clase activo, deseleccionamos todas y seteamos la nueva como activa
                tabContents.forEach(tabContent => {
                    tabContent.classList.remove('active');
                })
                selectedTab.classList.add('active');
            })
        })

        //SPOTIFY
        window.onSpotifyIframeApiReady = (IFrameAPI) => {
            let element = document.getElementById('spotify-iframe');
            let episodeId = element.getAttribute("data-episode");
            let options = {
                width: '100%',
                height: '166',
                uri: `spotify:episode:${episodeId}`
            };
            let callback = (EmbedController) => {};
            IFrameAPI.createController(element, options, callback);
        };
    }
}