const BREAKPOINTS = {
    MD: 720
}

export const mobileMenuFunctionality = () => {
    let active = false
    const animationTime = 300;
    
    const toggleButton = document.querySelector('#toggler');
    const secondaryNav = document.querySelector('#secondary-nav');
    const htmlElement = document.querySelector('html');
    const bodyElement = document.querySelector('body');

    if(toggleButton && secondaryNav){
        toggleButton.addEventListener('click', () => {
            if(!active){
                toggleButton.classList.add('active');
                secondaryNav.classList.add('open');
                htmlElement.classList.add('no-scroll');
                bodyElement.classList.add('no-scroll');
            } else {
                toggleButton.classList.remove('active');
                secondaryNav.classList.remove('open');
                setTimeout(() => {
                    htmlElement.classList.remove('no-scroll');
                    bodyElement.classList.remove('no-scroll');
                }, animationTime);
            }
            active = !active;
        });
    }
}

export const notificationBarFunctionality = () => {
    const notificationBar = document.getElementById("notification-bar");
    const header = document.getElementById("header");
    const mobileNavbar = document.querySelector(".header__secondary-nav__list");
    
    if (notificationBar) {
        const notificationBarHeight = notificationBar.offsetHeight;
        mobileNavbar.style.paddingTop = `${notificationBarHeight}px`
        
    //     if (window.innerWidth >= BREAKPOINTS.MD) {
    //         document.body.style.paddingTop = `${notificationBarHeight + 104}px`;
    //     } else {
    //         document.body.style.paddingTop = `${notificationBarHeight + 54}px`;
    //     }

    //     const toggleStyles = () => {
    //         if (window.scrollY === 0) {
    //             header.style.top = `${notificationBarHeight}px`;
    //             notificationBar.style.opacity = 1;
    //         } else if (window.scrollY >= notificationBarHeight + 50) {
    //             header.style.top = `0`;
    //             notificationBar.style.opacity = 0;
    //         }
    //     };

    //     toggleStyles();

    //     window.addEventListener("scroll", function () {
    //         toggleStyles();
    //     });
    }
};

export const headerSliderHeroFunctionality = () => {
    const header = document.getElementById("header");
    const heroSlider = document.querySelector(".hero-slider");
    if(header && heroSlider){
        header.classList.add("--fixed");
    }
}

export const headerTransparentFunctionality = () => {
    const header = document.getElementById("header");
    const button = document.querySelector('#btn-talk');
    const firstElement = document.querySelector('section');

    if(!firstElement){
        return;
    }

    if(firstElement.classList.contains('hero-slider')){
        return;
    }

    if(firstElement.hasAttribute("header-type") && header){
        const headerType = firstElement.getAttribute('header-type');
        let scrolledToTop = true;
        
        function updateHeader(){
            header.classList.add('--fixed')
            if (!scrolledToTop) {
                // Limpiar clases anteriores
                header.classList.remove('--transparent-white', '--transparent-blue');
                button.classList.remove('outline-white');
            } else {
                // Aplicar clase según el tipo de encabezado
                if (headerType === 'white') {
                    header.classList.add('--transparent-white');
                    button.classList.add('outline-white');
                } else if (headerType === 'blue') {
                    header.classList.add('--transparent-blue');
                    button.classList.remove('outline');
                }
            }
        }
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                scrolledToTop = false;
            } else {
                scrolledToTop = true;
            }
            updateHeader();
        });
        updateHeader();
    }

}
