export const socialSharing = function (name) {
    // Share Links
    const shareIconsWrapper = document.getElementById("social-links");

    if (shareIconsWrapper) {
        const copyLinkBtn =  document.getElementById("link-share")
        // const link = encodeURI(window.location.href);
        const link = 'https://www.veritran.com/es/recursos/blog/pagos-en-tiempo-real-oferta-imprescindible-para-tu-billetera-digital/';
        const facebookBtn = document.getElementById("fb-share");
        facebookBtn.href = `https://www.facebook.com/share.php?u=${link}`;

        const twitterBtn = document.getElementById("tw-share");
        twitterBtn.href = `https://www.twitter.com/share?&url=${link}`;

        const linkedinBtn = document.getElementById("in-share");
        linkedinBtn.href = `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;

        copyLinkBtn.addEventListener('click', ()=> {
            const copyStatus = document.getElementById('copyStatus');
            navigator.clipboard.writeText(link).then(function() {
                copyStatus.style.display = 'block' 

                setTimeout(() => {
                    copyStatus.style.display = 'none' 
                }, 2500);
            }, function(err) {
                console.error("Error al copiar el enlace al portapapeles: ", err);
            });
        })
    }
};
