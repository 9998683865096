export function useCasesDropdown() {
    const instances = document.querySelectorAll('.items-use-cases-horizontal');

    function handleDropdown(instance) {
        const dropdown = instance.querySelector('.items-use-cases-horizontal__dropdown');
        const itemContainer = dropdown.querySelector('.items-use-cases-horizontal__dropdown__choices-container');
        const dropdownIcon = dropdown.querySelector('.items-use-cases-horizontal__dropdown__icon');
        const choices = dropdown.querySelectorAll('.items-use-cases-horizontal__dropdown__choice');
        const cards = instance.querySelectorAll('.items-use-cases-horizontal__dropdown__card');
        let dropdownTitle = dropdown.querySelector('.items-use-cases-horizontal__dropdown__title');
        let selectedChoice = 1;

        dropdown.addEventListener('click', function () {
            const isActive = itemContainer.classList.contains('--active');
            itemContainer.classList.toggle('--active', !isActive);
            dropdownIcon.classList.toggle('--active', !isActive);
        });

        choices.forEach((choice, index) => {
            choice.addEventListener('click', function () {
                selectedChoice = index;
                choices.forEach((c) => c.classList.remove('--active'));
                choice.classList.add('--active');
                dropdownTitle.innerHTML = choice.innerHTML;
                cards.forEach((card) => card.classList.remove('--active'));
                cards[index].classList.add('--active');
            });
        });
    }

    function handleTabs(instance) {
        const content = instance.querySelector('.items-use-cases-horizontal__content');
        const tabs = content.querySelectorAll('.items-use-cases-horizontal__tab');
        const cards = content.querySelectorAll('.items-use-cases-horizontal__card');

        tabs.forEach((tab, index) => {
            tab.addEventListener('click', function () {
                tabs.forEach((t) => t.classList.remove('--active'));
                tab.classList.add('--active');
                cards.forEach((card) => card.classList.remove('--active'));
                cards[index].classList.add('--active');
            });
        });
    }

    function handleViews(instance) {
        const dropdown = instance.querySelector('.items-use-cases-horizontal__dropdown');
        const content = instance.querySelector('.items-use-cases-horizontal__content');

        if (dropdown && window.innerWidth <= 720) {
            handleDropdown(instance);
        }

        if (content && window.innerWidth > 720) {
            handleTabs(instance);
        }
    }

    instances.forEach((instance) => {
        handleViews(instance);
    });

    window.addEventListener('resize', function () {
        instances.forEach((instance) => {
            handleViews(instance);
        });
    });
}

export function useCasesDynamicId(){
    const instances = document.querySelectorAll('.items-use-cases');

    if(instances){
        instances.forEach((instance, index) => {
            if (!instance.id) {
                instance.id = `items-use-cases-${index}`;
            }
        });
    }
}

export function handleAnchorButton(){
    const useCases = document.querySelectorAll('.items-use-cases-horizontal');
    const useCasesAnchor = document.querySelector('#anchor-packages');
    const packagesForm = document.querySelector('#packages-form');
    if(useCasesAnchor && packagesForm){
        const starterField = packagesForm.querySelector('#starter-package');
        const advancedField = packagesForm.querySelector('#advanced-package');
        const ultimateField = packagesForm.querySelector('#ultimate-package');
        const interestedInField = packagesForm.querySelector('#interested_in');
        const interestedInContainer = interestedInField.parentElement;   
        useCases.forEach((instance) => {
            const button = instance.querySelector('#anchor-packages');
            const packageData = button.getAttribute('package-data');
            button.addEventListener('click', function () {
                console.log(packageData)
                if(packageData === 'starter'){
                    interestedInField.value = 'Starter'
                    interestedInContainer.classList.add('--filled');
                    starterField.value = 'Starter';
                    advancedField.value = '';
                    ultimateField.value = '';
                }
                if(packageData === 'advanced'){
                    interestedInField.value = 'Advanced'
                    interestedInContainer.classList.add('--filled');
                    starterField.value = '';
                    advancedField.value = 'Advanced';
                    ultimateField.value = '';
                }
                if(packageData === 'ultimate'){
                    interestedInField.value = 'Ultimate'
                    interestedInContainer.classList.add('--filled');
                    starterField.value = '';
                    advancedField.value = '';
                    ultimateField.value = 'Ultimate';
                }
            });
        });

    }
}
